







import Vue from 'vue';
import {ref} from "@vue/composition-api";
import {set, push, child, get, getDatabase, onValue} from "firebase/database";
import {ref as firebaseRef} from "@firebase/database";
import firebase from "firebase/compat"; // @ is an alias to /src

export enum DoorOpenerState{
  None="none",
  Requested="requested",
  Waiting="waiting",
  Success="success",
  Failed="failed"
}
export default Vue.extend({
  name: 'Home',
  components: {},
  setup(){
    const users = ref([])
    const db = getDatabase();
    const dbRef = firebaseRef(db)
    const usersRef = firebaseRef(db, "users")
    const state = ref(DoorOpenerState.None)

    get(child(dbRef, "users")).then((snapshot)=>{
      console.log("val", snapshot.val())
      users.value = snapshot.val()
    })

    async function openDoor(){
      state.value = DoorOpenerState.Requested
      const doorOpenerRef = push(child(dbRef, 'doorOpener/')).ref;
      state.value = DoorOpenerState.Waiting
      onValue(doorOpenerRef, (snapshot)=>{
        console.log(snapshot.val)
        state.value = DoorOpenerState.Success
      })

      const data = {
        status: "waiting",
        timestamp: firebase.database.ServerValue.TIMESTAMP,
        user: localStorage.getItem('uid')
      }
      await set(doorOpenerRef, data)
      state.value = DoorOpenerState.Success
      setTimeout(()=>{state.value = DoorOpenerState.None}, 3000)
    }

    return {
      state,
      users,
      openDoor
    }
  }
});
